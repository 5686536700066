import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "layout"
import Upload from "../../../Upload"
import MedEnsureCart from "../../Cart"
import guidelines from "./utils/uploadGuidelines.json"
import { medEnsureSteps } from "../../utils/medEnsureSteps"
import { MedEnsureContext } from "../../MedEnsureContext/MedEnsureContext"

const MedEnsureUpload = ({ pageContext, location }) => {
  const { medEnsureState, medEnsureDispatch } = useContext(MedEnsureContext)
  const data = useStaticQuery(graphql`
    {
      document: file(relativePath: { eq: "icons/upload__document.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      prescription: file(
        relativePath: { eq: "icons/upload__prescription.png" }
      ) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const fileUploadPrescription = data?.prescription?.childImageSharp?.fixed
  const MAX_FILE_SIZE_IN_BYTES = 4000000
  const MAX_FILE_COUNT = 6

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      currentStep={1}
      totalSteps={4}
      seoTitle={pageContext?.module?.seoTitle}
      pageContext={pageContext}
      steps={medEnsureSteps}
      location={location}
      path={pageContext?.module.path}
      hasMobileStepper={true}
    >
      <Upload
        pageContext={pageContext}
        icon={fileUploadPrescription}
        maxFileCount={MAX_FILE_COUNT}
        maxFileSize={MAX_FILE_SIZE_IN_BYTES}
        guidelines={guidelines}
        dropzoneLabel="Upload Prescription"
        cartContainer={
          <MedEnsureCart
            open={{ documentsCard: true, medicinesCard: false }}
            medEnsureState={medEnsureState}
            medEnsureDispatch={medEnsureDispatch}
            pageContext={pageContext}
          />
        }
        moduleState={medEnsureState}
        documents={medEnsureState.documents}
        moduleDispatch={medEnsureDispatch}
        module="medensure"
      />
    </Layout>
  )
}

export default MedEnsureUpload
