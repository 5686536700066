import React from "react"

import MedicinesCard from "../../Cart/MedicinesCard"
import DocumentsCard from "../../Cart/DocumentsCard"

import { getPathName } from "../../../services/general"

const MedEnsureCart = ({
  open,
  medEnsureState,
  medEnsureDispatch,
  pageContext,
}) => {
  return (
    <div className="mt-2-mobile">
      <DocumentsCard
        open={open?.documentsCard}
        baseRoute={pageContext?.basePath}
        isFullSize={open?.documentsIsFullSize}
        moduleState={medEnsureState}
        moduleDispatch={medEnsureDispatch}
        pageContext={pageContext}
      />
      {!getPathName()?.includes("upload") && (
        <MedicinesCard
          open={open?.medicinesCard}
          baseRoute={pageContext?.basePath}
          moduleState={medEnsureState}
          moduleDispatch={medEnsureDispatch}
          pageContext={pageContext}
          flow="medensure"
        />
      )}
    </div>
  )
}

export default MedEnsureCart
